import * as React from "react";
const SvgFinalPlancite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 800 600"
    width="1em"
    height="1em"
    {...props}
  >
    <g transform="matrix(.85018 0 0 .84373 .043 220.667)">
      <clipPath id="final-plancite_svg__a">
        <path d="M0 0h946v396H0z" />
      </clipPath>
      <g clipPath="url(#final-plancite_svg__a)">
        <path
          d="M30.565 86.962h226.96v54.022H30.565z"
          style={{
            fill: "#030404",
          }}
          transform="matrix(-4.16667 0 0 4.16667 1073.022 -192.338)"
        />
        <text
          style={{
            fontFamily: "&quot",
            fontSize: "50.959px",
            fill: "#231f20",
          }}
          transform="translate(.004 151.987) scale(4.16667)"
        >
          {"P"}
        </text>
        <text
          style={{
            fontFamily: "&quot",
            fontSize: "50.959px",
            fill: "#231f20",
          }}
          transform="translate(144.942 151.987) scale(4.16667)"
        >
          {"LAN"}
        </text>
        <text
          style={{
            fontFamily: "&quot",
            fontSize: "50.959px",
            fill: "#fff",
          }}
          transform="translate(395.571 359.221) scale(4.16667)"
        >
          {"C"}
        </text>
        <text
          style={{
            fontFamily: "&quot",
            fontSize: "50.959px",
            fill: "#fff",
          }}
          transform="translate(558.472 359.221) scale(4.16667)"
        >
          {"ITE"}
        </text>
      </g>
    </g>
  </svg>
);
export default SvgFinalPlancite;
