import React, { useEffect, useState } from 'react';
import { CommunityData, CommunityProps } from '../types/types';
import { Helmet } from 'react-helmet-async';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import CommunityHeader from './CommunityHeader';
import CommunityFooter from './CommunityFooter';
import { useParams } from "react-router-dom";
import { fetchCommunityNamed } from '../utils/googleSheetsAPI';

interface RouteParams {
  communityName: string;
}

const Community: React.FC<CommunityProps> = () => {
  const [community, setCommunity] = useState<CommunityData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { communityName } = useParams<RouteParams>();
  const pageTitle = `${communityName} - Community Plan`;

  const communityContainerStyle = {
    paddingLeft: '20px',
    paddingRight: '20px',
  };

  useEffect(() => {
    const fetchCommunity = async () => {
      try {
      const response = await fetchCommunityNamed(communityName);

      if (response === null) {
        setError(new Error(`Community ${communityName} not found`));
      } else {    
        setCommunity(response);
      }
      setIsLoading(false);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("An unexpected error occurred"));
      setIsLoading(false);
    }
  };

    fetchCommunity();
  }, [communityName]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!community) {
    return <p>No community data available.</p>;
  } else {

  return (
    <>
      <Helmet>
        <title>{pageTitle} | PlanCite</title>
        <meta name="description" content={`Community plan details for ${community.community}`}/>
        <meta name="keywords" content="Comprehensive Plan, Master Plan, Public Plan, Community Plan" />
      </Helmet>
      <CommunityHeader/>
      <div style={communityContainerStyle}>
      <Typography variant="h4" gutterBottom>
        {community.community}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Plan Type</TableCell>
              <TableCell>{community.plan_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adoption</TableCell>
              <TableCell>{community.adoption}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Revised</TableCell>
              <TableCell>{community.last_revised}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Format Type</TableCell>
              <TableCell>{community.format}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Accessed</TableCell>
              <TableCell>{community.accessed}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Plan Link</TableCell>
              <TableCell>
                <a href={community.link} target="_blank" rel="noopener noreferrer">
                  {community.link}
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Verification Date</TableCell>
              <TableCell>{community.verification_date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Verified By</TableCell>
              <TableCell>{community.verified_by}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    <CommunityFooter/>
    </>
  );
  }
};

export default Community;