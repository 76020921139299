import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Community from './components/Community';
import { CommunityData } from './types/types';
import { fetchCommunityData } from './utils/googleSheetsAPI';
import Home from './components/Home';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-H3LK14L8NH");

const App: React.FC = () => {

  useEffect(() => {
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname + window.location.search 
    });
  }, []);

  return (
    <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Home/>}
          />
           <Route
          path="/community/:communityName"
          component={Community}
        />
        </Switch>
    </Router>
  );
};

export default App;