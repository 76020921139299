// components/Home.tsx
import React, { useEffect } from 'react';
import { CommunityData } from '../types/types';
import Logo from './Logo';
import SearchBox from './SearchBox';
import styles from '../styles/Home.module.css'
import { fetchCommunityData } from '../utils/googleSheetsAPI';
import { useState } from 'react';

interface HomeProps {
  communities: CommunityData[];
}

const Home: React.FC = () => {
    const [communities, setCommunities] = useState<CommunityData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    
    useEffect(() => {
      const fetchData = async () => {
    try {
      const data = await fetchCommunityData();
      setCommunities(data);
      setIsLoading(false);
    } catch (err) {
      setError('An error occurred while fetching community data.');
      setIsLoading(false);
    }
  };
  fetchData();
  } , []);
  return (
    <div className={styles.pageContainer}>
      <Logo  footText='PlanCite is for the accessibility and transparency of all public plans.'/>
      <div className={styles.mainContent}>
      <p>Find your community's plan for the future:</p>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <SearchBox communities={communities} />
      )}
      </div>
    </div>
  );
};

export default Home;
