import React from 'react';
import styles from '../styles/CommunityFooter.module.css'; // Import the CSS file, which we will create in the next step

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <p>
        Anyone can submit their community's plan to PlanCite by emailing the
        plan's link to{' '}
        <a href="mailto:plancite@gmail.com" target="_blank" rel="noreferrer">
          plancite@gmail.com
        </a>
        .
      </p>
      <p>
        Planners and Locality Representatives, please utilize your official
        email address to verify by emailing{' '}
        <a href="mailto:plancite@gmail.com" target="_blank" rel="noreferrer">
          plancite@gmail.com
        </a>
        .
      </p>
    </footer>
  );
};

export default Footer;
