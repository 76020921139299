import React, { useEffect, useState } from 'react';
import SvgFinalPlancite from '../assets/FinalPlancite'; // Replace with your logo file path
import styles from '../styles/Logo.module.css';

interface LogoProps {
    size?: 'small' | 'large';
    footText?: string;
}

const Logo: React.FC<LogoProps> = ({size = 'large', footText = undefined}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
  useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 768;
  const logoStyle = {
      width: isMobile ? '350px' : (size === 'small' ? '200px' : '600px'),
      height: isMobile ? '350px' : (size === 'small' ? '200px' : '600px'),
  };
  
  return (
    <div className={styles.logoContainer}>
      <SvgFinalPlancite alt="PlanCite Logo" className={styles.logo} style={logoStyle} />
      <div>
      {
        footText && (
            <p className={styles.footText}>
                {footText}
            </p>
        )
      }
      </div>
    </div>
  );
};

export default Logo;