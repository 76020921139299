import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CommunityData } from '../types/types';
import styles from '../styles/SearchBox.module.css';
import { fetchCommunityDataByName } from '../utils/googleSheetsAPI';
import { debounce } from 'lodash';

interface SearchBoxProps {
  communities: CommunityData[];
}

const SearchBox: React.FC<SearchBoxProps> = ({ communities }) => {
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(communities);

  const handleOnSelect = (event: any, value: any) => {
    if (value) {
      history.push(`/community/${value.community}`);
    }
  };

  const fetchSearchResults = debounce(async (searchText: string) => {
    // Make the request to your backend API here, and set the search results in the state.
    // Replace the URL and fetch logic with what's appropriate for your backend.
    const response = await fetchCommunityDataByName(searchText);
    setOptions(response);
  }, 500); // The delay in milliseconds. Adjust as needed.


  useEffect(() => {
    if (inputValue === '') {
      // If the search box is cleared, reset the options to the initial communities list.
      setOptions(communities);
    } else {
      // Otherwise, fetch the search results from the backend.
      fetchSearchResults(inputValue);
    }
  }, [inputValue, communities]);

  return (
    <div className={styles.searchContainer}>
      <Autocomplete
        id="search-box"
        options={options}
        getOptionLabel={(option) => option.community}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for a community"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
          />
        )}
        onChange={handleOnSelect}
      />
    </div>
  );
};

export default SearchBox;