import axios from 'axios';
import { CommunityData } from '../types/types';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const fetchCommunityData = async (): Promise<CommunityData[]> => {
  const { data } = await axios.get(`${SERVER_URL}/api/community-data`);
  return data;
};

// search for a community by name
export const fetchCommunityDataByName = async (communityName: string): Promise<CommunityData[]> => {
  const { data } = await axios.get(`${SERVER_URL}/api/search?query=${communityName}`);
  return data;
};

export const fetchCommunityNamed = async (name: string): Promise<CommunityData> => {
  const { data } = await axios.get(`${SERVER_URL}/api/community/${name}`);
  return data;
}