import React from 'react';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import styles from '../styles/CommunityHeader.module.css';

const CommunityHeader: React.FC = () => {

  return (
    <div className={styles.container}>
        <Link to="/">
      <Logo size="small" />
      </Link>
      <div className={styles.text}>Accessibility & Transparency of All Public Plans</div>
        
    </div>
  );
};

export default CommunityHeader;
